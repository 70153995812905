import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKiwiBird, faFish } from '@fortawesome/free-solid-svg-icons';
import { api } from '../api';
import hs from '../assets/img/iam.png';
import bgr from '../assets/img/bgr2.png';
import marketing from '../assets/Video/marketing.mp4';
import EntryList from '../components/Pages/GiveAway/GiveawayList';
import AddGiveawayForm from '../components/Pages/GiveAway/AddGiveawayForm';
import WinnerDisplay from '../components/Pages/GiveAway/WinnerDisplay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const checkEmailExists = async (email) => {
  try {
    const response = await api.get(`contacts/BusinessByEmail/${email}`);
    return response;
  } catch (error) {
    console.error('Error checking email:', error);
    return false;
  }
};

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Name must be at least 2 characters long').required('Name is required'),
  email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid Email').required('Email is required'),
});

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    const emailExists = await checkEmailExists(data.email);
    if (emailExists.data && emailExists.data.exists) {
      toast.error('Email is already registered');
      return;
    }
    try {
      const response = await api.post('contacts/Busines_contact', data);
      toast.success(response.data.message);
    } catch (error) {
      toast.error('Error submitting form');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const [entries, setEntries] = useState([]);
  const [winner, setWinner] = useState(null);

  const fetchEntries = async () => {
    try {
      const response = await api.get("/entries");
      setEntries(response.data);
    } catch (error) {
      console.error("Failed to fetch entries:", error);
    }
  };

  const pickWinner = async () => {
    try {
      const response = await api.get("/winner");
      setWinner(response.data.winner);
    } catch (error) {
      console.error("Failed to pick winner:", error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <div className="relative top-12 min-h-full overflow-hidden">
      <ToastContainer />
      <div className="container w-full mt-8 mx-auto px-6 md:px-8 relative z-20">
        <div className="flex flex-col lg:flex-row items-start justify-between space-y-6 lg:space-y-0 lg:space-x-6">

          <div className="flex-1 lg:w-2/3 w-full mt-0">
            <div className='flex flex-col md:flex-row gap-4'>
              <div className='flex-1 md:w-1/2'>
                <h1
                  className={`text-4xl lg:text-5xl font-extrabold mb-2 sm:mb-4 text-start 
                  transition-all duration-700 ease-in-out transform text-center
                  ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} hover:scale-105`}
                >
                  <span className="inline-block">
                    Join the Movement: Empower Your Startup or SME Today!
                  </span>
                </h1>

                <div className="text-center px-4">
                  <p className='text-md text-gray-500 font-urbanist text-justify'>
                    Ready to take your business to the next level? Our team is dedicated to boosting the online presence of startups and SMEs. Connect with us today and let’s make your digital footprint unforgettable!
                  </p>
                </div>

                <div className="pt-4 w-full flex flex-col justify-center items-center mx-auto">
                  <h1 className="text-4xl font-bold shadow-sm drop-shadow-xl font-urbanist text-center">Act Now - Your Future Awaits!</h1>
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
                    <div className="flex flex-col sm:flex-row">
                      <div className="m-4 w-full">
                        <input
                          type="text"
                          name="name"
                          {...register('name')}
                          className={`border-2 rounded-lg p-3 sm:p-2 w-full focus:outline-none focus:ring-2 shadow-lg ${errors.name ? 'border-red-500 focus:ring-red-500' : isValid ? 'border-green-500 focus:ring-green-500' : 'border-[#FD5F20]'}`}
                          placeholder="Enter your name"
                        />
                        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                      </div>
                      <div className="m-4 w-full">
                        <input
                          type="email"
                          name="email"
                          {...register('email')}
                          className={`border-2 rounded-lg p-3 sm:p-2 w-full focus:outline-none focus:ring-2 shadow-lg ${errors.email ? 'border-red-500 focus:ring-red-500' : isValid ? 'border-green-500 focus:ring-green-500' : 'border-[#FD5F20]'}`}
                          placeholder="Enter your email"
                        />
                        {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="w-full text-lg font-semibold bg-[#FD5F20] text-gray-300 p-3 sm:p-2 rounded-lg hover:bg-[#e04e1c] transition duration-200 shadow-lg"
                    >
                      Take Your Business to the Next Level
                    </button>
                  </form>
                </div>
              </div>
              <div className="flex-1 w-full md:w-1/2 p-4 space-y-4">
                <div className="w-full">
                  <img
                    src={bgr}
                    alt="Background Image"
                    className="object-cover w-full h-auto"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 m-2 pt-4 w-full">
              <div className="relative w-full max-w-xs md:max-w-xs lg:max-w-lg xl:max-w-xl md:w-1/2"> {/* Small container */}
                <video
                  src={marketing}
                  title="Digital Marketing"
                  className="w-full aspect-video object-cover rounded-lg border border-red-500"
                  controls
                  autoPlay
                  muted
                  loop
                />
              </div>
              <div className="bg-gray-50 flex flex-col w-full md:w-1/2 items-center justify-center">
                <div className="w-full space-y-2">
                  <AddGiveawayForm onEntryAdded={fetchEntries} />
                  {/* <EntryList entries={entries} /> */}
                  {/* <button
                    onClick={pickWinner}
                    className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-500"
                  >
                    Pick a Winner
                  </button>
                  <WinnerDisplay winner={winner} /> */}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default HeroSection;