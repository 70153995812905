import React, { useState, useEffect } from 'react';


import PlaylistPage from './BlogPlayList';


const Blog=() =>{
 

  return (
    <div className="md:mt-28 min-h-screen ">
      
<PlaylistPage/>

      
      
    </div>
  );
}

export default Blog;
